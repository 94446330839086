import React from 'react';
import { graphql,Link } from 'gatsby';
import Markdown from '../components/views/Markdown';
import styled from 'styled-components';
import TwoColLayout from '../components/layout/TwoColLayout';
import SideBar from '../components/views/SideBar';
import { FaHome,FaFolder,FaRegCalendarAlt,FaTag } from "react-icons/fa";
import { StaticImage, GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import MainToc from '../components/views/MainToc';
import Gitalk from 'gatsby-plugin-gitalk'
import '@suziwen/gitalk/dist/gitalk.css'
import moment from 'moment';
import { kebabCase } from 'lodash/string';
import { PageQuery } from '../../graphql-types';
import ShareButtons from '../components/parts/ShareButton';


type Props = {
  data : PageQuery
}

export default function PostSlugPage(props: Props) {
  const { body,id,excerpt,tableOfContents } = props.data.mdx;
  const { title,slug,icon,category,date,tags,thumbnail:image } = props.data.mdx.frontmatter;
  const { title:siteTitle,description:siteDesc } = props.data.site.siteMetadata;
  const thumbnail = props.data.mdx.frontmatter.thumbnail?.childImageSharp.fluid.src;
  const imageUrl = `${props.data.site.siteMetadata.siteUrl}${thumbnail}`;
  const pageUrl = `${props.data.site.siteMetadata.siteUrl}/${slug}`;
  const MainContent = styled.div`
    .pankuzu{
      font-size:13px;
    }
    .pankuzu a{
      text-decoration: none;
      color:#333;
    }
    .page-header{
      background-color: #333;
      padding: 35px;
    }
    .page-header .gatsby-image-wrapper{
      border-radius: 5px;
    }
    @media screen and (max-width: 480px) {
      .page-header .gatsby-image-wrapper{width: 130px;}
    }
    .page-header h1{
      font-size: 26px;
      font-weight: bold;
      color: white;
    }
    .page-header .icon{
      text-align: center;
    }
    .date,.category,.tag{
      font-size:13px;
      color:#ddd;
    }
    .post-meta a{
      text-decoration: none;
      color:#ddd;
    }
    .post-meta svg{
      transform: translate(1px,-2px);
    }
    .gt-container{
      margin-bottom: 40px;
    }
    .thumbnail{ text-align: center;  }
    .thumbnail img{
      display: block;
      margin: 0 auto;
      border: 1px solid black;
    }
    .gatsby-image-wrapper-constrained{ max-width: 100% !important; }
  `;
  let gitalkConfig = {
    id: id,
    title: title,
  }
  return (
    <TwoColLayout
      data={props.data}
      main={
        <MainContent>
          <Helmet>
            <html lang="ja" />
            <title>{title} | TERAKOYA</title>
            <meta name="description" content={siteDesc} />
            <meta property="og:url" content={pageUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${title}|${siteTitle}`} />
            <meta property="og:description" content={excerpt.replace(`\n`,"")} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:width" content="1200" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:title" content={`${title}|${siteTitle}`} />
            <meta name="twitter:description" content={excerpt.replace(`\n`,"")} />
          </Helmet>
          <p className='pankuzu'>
            <Link to="/"><FaHome /> HOME</Link> &gt; 
            <Link to={`/category/${kebabCase(category)}`}> <FaFolder /> {category} </Link> &gt; 
            <span> {title}</span>
          </p>
          <div className='page-header'>
            <Row>
              <Col md={2} xs={12}>
                <div className='icon'>
                  {getImage(icon as ImageDataLike) ? (
                    <GatsbyImage image={getImage(icon as ImageDataLike) as IGatsbyImageData} alt={title} />
                  ) : (
                    <StaticImage alt="no image" src="../../images/no-image.png"/>
                  )}
                </div>
              </Col>
              <Col md={10} xs={12}>
                <h1>{title}</h1>
                <div className='post-meta'>
                  <div className='date'><FaRegCalendarAlt /> {moment(date).format(`YYYY年M月D日 H:mm`)} </div>
                  <div className='category'>
                    <FaFolder /><Link to={`/category/${kebabCase(category)}`}> {category} </Link>
                  </div>
                  <div className='tag'>
                    <FaTag />
                    { tags.map((tag:string) => (
                      <Link key={tag} to={`/tag/${kebabCase(tag)}`}><span> { tag } </span></Link>
                    ))}
                  </div>
                </div>
             </Col>
            </Row>
          </div>
          <ShareButtons url={pageUrl} title={title} />
          <div className='thumbnail mt-4'>
            {getImage(image as ImageDataLike) && (
              <GatsbyImage image={getImage(image as ImageDataLike) as IGatsbyImageData} alt={title} className="img-fluid" />
            )}
          </div>
          <MainToc toc={tableOfContents}/>
          <Markdown>{body}</Markdown>
          <Gitalk options={gitalkConfig}/>
        </MainContent>
      }
      side={
        <SideBar data={props.data} />
      }
    />
  );
}

export const query = graphql`
  query Page($id: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        headerLinks{
          label
          url
        }
      }
    }
    mdx(id: { eq: $id }) {
      frontmatter {
        date
        title
        slug
        category
        thumbnail {
          childImageSharp {
            gatsbyImageData
            fluid {
              src
            }
          }
        }
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
        tags
      }
      id
      excerpt(truncate: true)
      body
      tableOfContents
      parent {
        ... on File {
          name
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  dl, ol, ul { margin-top: 0; margin-bottom: 0; }
  #main-toc { 
    border-top: 5px solid #ccc;
    border-bottom: 5px solid #ccc; 
    background-color: #fafafa; 
    padding: 15px; 
    margin:30px;
    @media screen and (max-width: 480px) {
      margin:15px 0;
    }
  }
  h4{ font-size: 20px; font-weight: bold; text-align: center; }
  #main-toc > ul.toc { padding-left: 0; }
  #main-toc > ul.toc > li { list-style-type: none; margin: 0 5px; font-size: 16px; counter-increment: h2; }
  #main-toc > ul.toc > li:before { content: counter(h2) "."; }
  ul.toc > li > a { color: #000; font-weight:normal; text-decoration: none; border-bottom: 0; padding-left: 6px; }
  ul.toc > li > a:hover { text-decoration:underline; color:black; }
  #main-toc > ul.toc > li > ul.toc { margin: 5px 0; }
  #main-toc > ul.toc > li > ul.toc > li { list-style-type: none; font-size: 15px; counter-increment: h3;}
  #main-toc > ul.toc > li > ul.toc > li:before { content: counter(h2) "." counter(h3);}
  #main-toc > ul.toc > li > ul.toc > li > ul.toc { display: none;}
`;

function renderItems(items: any,depth: number) {
  return (
    <ul className="toc">
      {items && items.map((item: any, index: number) => {
        return (
          <li key={item.title}>
            <a href={item.url} >{ `${item.title}` }</a>
            { item.items && renderItems(item.items,depth+1) }
          </li>
        );
      })}
    </ul>
  );
}

function MainToc(props: any) {
  const { toc } = props;
  return (
    <Wrapper>
      <nav id="main-toc">
        <h4>目次</h4>
        {renderItems(toc.items,1)}
      </nav>
    </Wrapper>
  );
}

export default MainToc;